import {
  DollarCircleOutlined,
  DotChartOutlined,
  DownloadOutlined,
  GlobalOutlined,
  HeartFilled,
  HeartOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import {
  Badge,
  Card,
  Col,
  Empty,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd"
import { t } from "i18next"
import moment from "moment"
import Link from "next/link"
// import router from "next/router"
import dynamic from "next/dynamic"
import React, { useEffect, useState } from "react"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { getSignedObjectUrl, theme } from "../../.."
import {
  addFavoriteJob,
  deleteFavoriteJob,
  getFavoriteJobById,
} from "../../../../services/favorite_job"
import {
  fetchJobSeekerById,
  referJobSeeker,
} from "../../../../services/jobseeker"
import { PdfDownload, addhttp } from "../../../../utils"
import generatePdfDocument from "../../../../utils/generatePdf"
import { JobTypeTranslation } from "../../../../utils/jobTypeTranslation"
import { GetTopicsLabel } from "../../../constants"
import { getBadgeValue, getTagColor, getTagIcon } from "../../../utility"
import { ArrayTranslate } from "../JobDetail/TopSectionCard"
import { JobSeekers } from "../JobSeekers"

const Button = dynamic(
  () => import("../../atom/Button").then((mod) => mod.ButtonComponent),
  { ssr: false }
)

const JobDetailModal = dynamic(
  () =>
    import("../../molecules/JobDetailModal").then((mod) => mod.JobDetailModal),
  { ssr: false }
)

const Wrapper = styled.div`
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  .ant-tag {
    font-size: 10px;
  }
  .date-job {
    margin-top: 10px;
  }
  .action-icons {
    margin-top: 18px;
    text-align: center;
  }
  .ant-ribbon.ant-ribbon-placement-end {
    right: -7px !important;
    top: -10px;
    background-color: #6b0f1a;
    background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
  }
  .ant-ribbon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
  }
  .ant-card-head {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ant-card-body {
    padding-top: 0;
    padding-left: 15px;
    padding-right: 6px;
    padding-bottom: 14px;
  }
  .ant-card-head-title {
    padding: 10px 0 !important;
  }

  .job-title {
    margin-top: 5px;
    font-size: 16px;
    a {
      color: inherit;
    }
  }
  .company-url,
  .occupation-list {
    display: flex;
    gap: 9px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .industry-type {
    font-weight: normal;
    color: #7d857c;
  }
  .ant-list-item {
    padding: 0;
  }
  .list-desc {
    background: #f5f8ff;
    margin-top: 10px;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    svg {
      font-size: 16px;
    }
    .ant-list-item-meta {
      display: flex;
      margin-bottom: 8px;
    }
    .ant-list-item-meta-content {
      display: flex;
    }
    .ant-list-item-meta-title {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      min-width: 75px;
      margin-right: 10px;
      margin-top: -2px;
    }
    .last-child {
      margin-bottom: 0;
    }
  }
  .ant-list-item-meta-description {
    overflow: auto;
    word-wrap: break-word;
    font-size: 12px;
  }
  .download-btn {
    a {
      color: #000000;
    }
  }
`
const Box = styled.div`
  margin-top: 12px;
  border: 1px solid #eff2f5;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  display: flex;
  .box-title {
    padding: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-width: 150px;
    max-width: 150px;
    border-right: 1px solid #eff2f5;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box-content {
    padding: 15px;
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  .empty-box-content {
    margin: auto;
    padding: 15px;
    font-size: 12px;
  }
  .LinesEllipsis-ellipsis {
    cursor: pointer;
  }
`
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  .btn-wrapper {
    display: flex;
    .loading-icon {
      svg {
        font-size: 12px !important;
      }
    }
    .refer-btn {
      margin-left: 20px;
      svg {
        font-size: 32px;
        fill: #cf1321;
      }
    }

    .fav-btn {
      border-color: black;
      margin-left: 20px;
      width: 167px;
      display: flex;
      align-items: center;
      svg {
        font-size: 15px;
        fill: #cf1321;
      }
    }
  }

  .date-job {
    font-size: 12px;
  }
`
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  .ant-spin-container {
    height: 120px;
    overflow-x: scroll;
  }
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  gap: 0.1rem;
  padding: 1rem 2rem;
  width: 100%;
  button {
    min-width: 100%;
  }
`

interface IJobCardProps {
  id?: string
  job: any
  mutation: any
  editJob?: boolean
  deleteJob?: boolean
  redirectEdit?: string
  isAgent?: boolean
  showRibbon?: boolean
  isAgentAdmin?: boolean
  draftJob?: boolean
  ref?: React.Ref<HTMLDivElement>
  applicantId?: string | null
  loginId?: string | null
}

const JobCard: React.FC<IJobCardProps> = ({
  id,
  job,
  mutation,
  editJob,
  deleteJob,
  redirectEdit,
  isAgent,
  isAgentAdmin,
  applicantId,
  showRibbon = true,
  ref,
  loginId,
}) => {
  const [signedObject, setSignedObject] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalData, setModalData] = useState({ title: "", body: "" })
  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [pdfDownloading, setPdfDownloading] = useState(false)

  /* eslint-disable */
  const Linkify = (children) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g
    if (children) {
      const words = children?.split(urlPattern)
      const formattedChat = words?.map((word, index) => {
        if (word.match(urlPattern)) {
          // If the word is a URL, convert it to a clickable link
          return `<a key=${index} href=${word} target="_blank">${word}</a>`
        } else {
          return `<span key=${index}>${word}</span>`
        }
      })
      return formattedChat.join("") // Join the array into a single string
    }
    return children
  }

  const {
    data: favoriteJob,
    isLoading: isLoadingApplicant,
    isFetching: isFetchingApplicant,
  } = useQuery<any, Error>(
    ["job", job?.id],
    () => getFavoriteJobById(job?.id),
    {
      enabled: !!isAgent,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
  const [favourite, setFavourite] = useState(
    favoriteJob?.data?.is_favorite || false
  )
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const { mutate: addToFav } = useMutation(addFavoriteJob, {
    onSuccess: () => {
      setFavourite(true)
    },
  })
  const { mutate: removeFromFav } = useMutation(deleteFavoriteJob, {
    onSuccess: () => {
      setFavourite(false)
    },
  })
  const handleClickFavorite = (id) => {
    if (!favourite) {
      addToFav({ job_id: id })
    } else {
      removeFromFav(id)
    }
  }

  const handleModalChange = (type: string, body: string) => {
    setModalData({
      title: type,
      body,
    })
    setDetailModalVisible(true)
  }

  const workDetailsEventListener = (e: any) => {
    e.preventDefault()
    handleModalChange(t("Work details"), job?.job_overview.work_details)
  }

  const qualificationEventListener = (e: any) => {
    e.preventDefault()
    handleModalChange(
      t("Qualification requirements"),
      job?.job_offer_point.qualification_requirement.info
    )
  }

  const handleReflow = (rleState, redirectEdit, job, id) => {
    const { clamped } = rleState

    if (clamped) {
      if (document.getElementById(`${id}-see-more`)) return
      const anchor = document.createElement("a")
      const span = document.createElement("span")
      span.setAttribute("id", `${id}-see-more`)
      span.textContent = `${t("see more")}`
      const body = document.querySelector(`.box-content-${id}`)
      if (redirectEdit) {
        anchor.href = `#`
        anchor.setAttribute("id", `work-detail-${id}`)
        anchor.addEventListener("click", workDetailsEventListener)
      } else {
        anchor.href = "#"
        anchor.setAttribute("id", `qualification-${id}`)
        anchor.addEventListener("click", qualificationEventListener)
      }
      anchor?.appendChild(span)
      body.appendChild(anchor)
    }
  }

  const { mutate, isLoading: isLoadingReferJobSeeker } = useMutation(
    referJobSeeker,
    {
      onSuccess: () => {
        notification.success({
          message: t("Job picked up successfully"),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        const error_type = error?.data?.error?.error
        if (error_type.includes("Duplicate entry")) {
          notification.error({ message: t("Already picked up") })
          return
        }
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  //get applicant by id
  const { isLoading, isFetching } = useQuery(
    ["fetchjobseekerbyId"],
    () => fetchJobSeekerById(applicantId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!applicantId,
      select: (response) => {
        return {
          id: response?.data.id,
          name: response?.data.name || "-",
          email_address: response?.data.email || "-",
          current_occupation_type:
            response?.data.current_occupation_type || "-",
          expected_occupation_type:
            response?.data.expected_occupation_type || "-",
          desired_industry_new: response?.data?.desired_industry_new || [],
          experienced_occupation_new:
            response?.data?.experienced_occupation_new || [],
          desired_occupation_new: response?.data?.desired_occupation_new || [],
          date_of_birth: response?.data?.date_of_birth || null,
          gender: response?.data?.gender || null,
          email: response?.data?.email || "",
          resume: response?.data?.resume || "",
          work_history: response?.data?.work_history || "",
          job_summary: response?.data?.job_summary || "",
          free_entry: response?.data?.free_entry || "",
          agent_comment: response?.data?.agent_comment || "",
          typical_format: response?.data?.typical_format || {},
        }
      },
    }
  )

  // pick up  job by Applicant
  const pickUpApplicant = (id: string) => {
    {
      mutate({
        job_id: job.id,
        applicant_id: id,
        status: "ピックアップ",
      })
    }
  }

  /**
   *this function is for getting the company name
   */
  const getCompany = (job) => {
    if (job?.agent_company_id) {
      return {name: job?.agent_company?.name, id: job?.agent_company_id}
    }
    return {name: job?.company?.name, id: job?.company_id}
  }

  const downloadPdf = async () => {
    setPdfDownloading(true)
    let interviewUrl = ""
    let imageUrl = ""
    if (job?.job_overview?.interview_image)
      interviewUrl = await getSignedObjectUrl(
        job?.job_overview?.interview_image
      )
    if (job?.image) imageUrl = await getSignedObjectUrl(job?.image)
    await generatePdfDocument(
      <PdfDownload
        jobData={job}
        isCompany={false}
        signedObject={imageUrl}
        interviewImageObject={interviewUrl}
      />,
      job.agent_company.name
        ? `${job.agent_company.name} - ${job?.one_phrase}.pdf`
        : `${job.company.name} - ${job?.one_phrase}.pdf`
    )
    setPdfDownloading(false)
  }

  useEffect(() => {
    return () => {
      ;[
        { id: `work-detail-${job?.id}`, func: workDetailsEventListener },
        {
          id: `qualification-${job?.id}`,
          func: qualificationEventListener,
        },
      ].forEach((item) => {
        const element = document.getElementById(item.id)
        if (element) element.removeEventListener("click", item.func)
      })
    }
  }, [])
  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />

  useEffect(() => {
    if (job?.image) {
      ;(async () => {
        try {
          const imageUrl = await getSignedObjectUrl(job?.image)
          setSignedObject(imageUrl)
        } catch (err) {
          message.error(t("Something went wrong"))
        }
      })()
    }
    return () => {
      setSignedObject(null)
    }
  }, [job])
  useEffect(() => {
    if (isAgent) {
      setFavourite(favoriteJob?.data?.is_favorite)
    }
    return () => {
      setFavourite(false)
    }
  }, [favoriteJob])
  if (!job) return null

  const handleDetailModalClose = () => setDetailModalVisible(false)
  /**
   * here
   * conversion of topic - object with category as key and array of string as values
   * to array of all items of each category array
   */

  const arrayOfAllItems = Object.values(job?.topic || {})
    .filter((value) => Array.isArray(value) && value.length > 0)
    .reduce(
      (accumulator?: string[], value?: string) => accumulator.concat(value),
      []
    )

  // /**
  //  *this function takes array of string
  //  *and returns translated one
  //  * @param arr
  //  * @returns
  //  */
  const getJptrans = (arr: string[]) => arr.map((item) => t(item))

  const handleSelfSearch = () => {
    localStorage.setItem(
      "sameCompany",
      JSON.stringify(
        job?.agent_company?.name ? job?.agent_company?.name : job?.company?.name
      )
    )
    localStorage.removeItem("selfOccupationType")
    // localStorage.setItem(
    //   "selfOccupationType",
    //   JSON.stringify(job?.occupation_type_new)
    // )
    applicantId
      ? window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?applicantId=${applicantId}&search=self`,
          "_blank"
        )
      : window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?search=self`,
          "_blank"
        )
  }
  const handleOtherSearch = () => {
    localStorage.setItem(
      "sameCompanyId",
      JSON.stringify(
        job?.company_id ? job?.company?.id : job?.agent_company?.agent?.id
      )
    )
    localStorage.setItem(
      "selfIndustryType",
      JSON.stringify(job?.industry_type_new)
    )
    applicantId
      ? window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?applicantId=${applicantId}&search=other`,
          "_blank"
        )
      : window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?search=other`,
          "_blank"
        )
  }

  const handleSearchOnOccupationType = (company, occupation) => {
    localStorage.setItem("sameCompany", JSON.stringify(company))
    localStorage.setItem("selfOccupationType", JSON.stringify(occupation))
    applicantId
      ? window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?applicantId=${applicantId}&search=self`,
          "_blank"
        )
      : window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?search=self`,
          "_blank"
        )
  }

  const handleSearchonClickCompanyName = (industry, company) => {
    localStorage.setItem("clickedCompanyName", JSON.stringify(company.name))
    localStorage.setItem("clickedCompanyId", JSON.stringify(company.id))
    localStorage.setItem("selfIndustryType", JSON.stringify(industry))
    applicantId
      ? window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?applicantId=${applicantId}&search=other`,
          "_blank"
        )
      : window.open(
          `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs?search=other`,
          "_blank"
        )
  }

  // this is the to filter the the company name to avoid job card company name
  const filterdata = job?.other_companies_and_agent_companies_same_industry
    ?.filter((item) => {
      const company = getCompany(item)
      const currentCompany = getCompany(job)
      if (company.name !== currentCompany.name) {
        return item
      }
    })
    .map((itm) => getCompany(itm))

  //this function removes given prefix from string if it starts with that prefix
  const removePrefixes = (inputString) => {
    // given prefecture to remove from string
    const prefixesToRemove = ["株式会社", "合同会社", "合弁会社"]
    for (const prefix of prefixesToRemove) {
      if (inputString.startsWith(prefix)) {
        inputString = inputString.slice(prefix.length)
      }
    }
    return inputString
  }

  //function to convert array  to object
  const arrayToObject = (array) => {
    const customObject = array.reduce((acc, value: any) => {
      const cleanedString = removePrefixes(value.name)
      return { ...acc, [cleanedString]: value }
    }, {})
    return customObject
  }

  function sortObjectByKey(obj) {
    const sortedKeys = Object.keys(obj).sort()
    const sortedObject = {}
    for (const key of sortedKeys) {
      sortedObject[key] = obj[key]
    }
    return Object.values(sortedObject)
  }

  const card = (
    <Card
      title={
        <>
          <Row>
            <Col flex={1}>
              <div style={{ whiteSpace: "normal" }}>
                {job?.job_type && (
                  <Tag color="purple">{JobTypeTranslation[job?.job_type]}</Tag>
                )}
                {job?.contract_type && (
                  <Tag color="cyan">{job?.contract_type}</Tag>
                )}
              </div>
              <div className="job-title">
                <Link
                  href={
                    (redirectEdit && {
                      pathname: `/${redirectEdit}/detail/${job?.id}`,
                    }) || {
                      pathname: `/jobs/detail/${job?.id}`,
                      query: {
                        ...(applicantId ? { applicantId } : {}),
                        ...(loginId ? { loginId } : {}),
                      },
                    }
                  }
                >
                  <a>
                    {job?.agent_company?.name
                      ? job?.agent_company?.name
                      : job?.company?.name}{" "}
                    {job?.one_phrase}
                  </a>
                </Link>
              </div>

              <div className="company-url">
                {(job?.company?.url || job?.agent_company?.url) && (
                  <a
                    href={
                      job?.company_id
                        ? addhttp(job?.company?.url)
                        : addhttp(job?.agent_company?.url)
                    }
                    target="_blank"
                  >
                    {job?.company_id
                      ? addhttp(job?.company?.url)
                      : addhttp(job?.agent_company?.url)}
                  </a>
                )}

                {job?.industry_type?.length ||
                job?.industry_type_new?.length ? (
                  <span className="industry-type">
                    (
                    {ArrayTranslate(
                      job?.industry_type_new?.length
                        ? job?.industry_type_new
                        : job?.industry_type
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col className="action-icons">
              <Space size="middle">
                <Link
                  href={
                    (redirectEdit && {
                      pathname: `/${redirectEdit}/detail/${job?.id}`,
                    }) || {
                      pathname: `/jobs/detail/${job?.id}`,
                      query: applicantId ? { applicantId: applicantId } : null,
                    }
                  }
                >
                  <a>
                    <span title="View">
                      <img
                        src="/assets/icons/eye.svg"
                        alt="view icon"
                        width={20}
                        height={20}
                      />
                    </span>
                  </a>
                </Link>
                {editJob &&
                  job?.approval_status !== "submitted" &&
                  job?.approval_status !== "end_job_request" && (
                    <Link
                      href={
                        (redirectEdit && {
                          pathname: `/${redirectEdit}/edit/${job?.id}`,
                        }) || {
                          pathname: `/jobs/edit/${job?.id}`,
                        }
                      }
                    >
                      <a>
                        <span title="Edit">
                          <img
                            src="/assets/icons/edit.svg"
                            alt="view icon"
                            width={20}
                            height={20}
                          />
                        </span>
                      </a>
                    </Link>
                  )}
                {deleteJob && (
                  <Popconfirm
                    placement="topRight"
                    title={"求人を削除してもよろしいですか？"}
                    onConfirm={() => mutation.mutate(job?.id)}
                    okText={t("Yes")}
                    cancelText={t("No")}
                  >
                    <a>
                      <span title="Delete">
                        <img
                          src="/assets/icons/delete.svg"
                          alt="view icon"
                          width={20}
                          height={20}
                        />
                      </span>
                    </a>
                  </Popconfirm>
                )}
              </Space>
              {job?.approval_status && (
                <div>
                  <Tag
                    color={getTagColor(job?.approval_status)}
                    style={{
                      width: "92px",
                      textAlign: "center",
                      marginTop: "10px",
                      marginRight: "0px",
                    }}
                  >
                    {getTagIcon(job?.approval_status)}
                    {job?.approval_status === "end_job_request"
                      ? t("Submitted")
                      : job?.approval_status === "ended"
                      ? t("Ended")
                      : t(job?.approval_status)}
                  </Tag>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {GetTopicsLabel(arrayOfAllItems).map((item, index) => {
              return (
                <Tag color="blue" key={index} style={{ marginBlock: "2px" }}>
                  {t(item.replace("topic", ""))}
                </Tag>
              )
            })}
          </Row>
        </>
      }
    >
      <Row>
        <Col flex="28%" style={{ overflow: "hidden" }}>
          <img
            src={signedObject || "/assets/icons/logo-job-placeholder.png"}
            alt="path"
            width={596}
            height={170}
            loading="lazy"
            style={{
              backgroundColor: "#f0f2f5",
              objectFit: "contain",
              objectPosition: "center",
              width: "100%",
              height: "250px",
              marginTop: "12px",
            }}
          />
          <List itemLayout="vertical" className="list-desc">
            <List.Item>
              <List.Item.Meta
                avatar={
                  <DotChartOutlined
                    style={{
                      color: "purple",
                    }}
                  />
                }
                title={t("Search Occupation")}
                description={
                  job?.occupation_type_new?.length
                    ? getJptrans(job?.occupation_type_new).toString()
                    : job?.occupation_type?.length
                    ? getJptrans(job?.occupation_type).toString()
                    : "-"
                }
              />
              <List.Item.Meta
                avatar={
                  <DollarCircleOutlined
                    style={{
                      color: "magenta",
                    }}
                  />
                }
                title={t("Annual income")}
                description={`${job?.annual_income?.from}万円〜${job?.annual_income?.to}万円`}
              />

              <List.Item.Meta
                avatar={
                  <GlobalOutlined
                    style={{
                      color: "green",
                    }}
                  />
                }
                title={t("Area")}
                description={job?.areas?.toString() || "-"}
                className="last-child"
              />
            </List.Item>
          </List>
          <div className="date-job">
            <div>
              {t("Recruiting company")} :
              {job?.company_id
                ? job?.company?.name
                : job?.agent_company?.agent?.company_name}
            </div>
            <div className="published-date">
              {t("Published date")} :
              {moment.utc(job?.publishing_date).format("YYYY/MM/DD")}
            </div>
            <div className="updated-date">
              {t("Updated date")} :
              {moment.utc(job?.updated_at).format("YYYY/MM/DD")}
            </div>
          </div>
        </Col>
        <Col flex="70%" style={{ marginLeft: "12px" }}>
          <Box>
            <Typography.Title className="box-title">
              {t("Qualification requirements")}
            </Typography.Title>
            {job?.job_offer_point?.qualification_requirement?.info ? (
              <HTMLEllipsis
                unsafeHTML={Linkify(
                  job?.job_offer_point?.qualification_requirement?.info
                )}
                maxLine={4}
                onReflow={(reFlow) => handleReflow(reFlow, false, job, job?.id)}
                basedOn="letters"
                className={`box-content box-content-${job?.id}`}
              />
            ) : (
              <div className="empty-box-content">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("No data")}
                />
              </div>
            )}
          </Box>
          <Box>
            <Typography.Title className="box-title">
              {t("Work details")}
            </Typography.Title>
            {job?.job_overview?.work_details ? (
              <HTMLEllipsis
                unsafeHTML={Linkify(job?.job_overview?.work_details)}
                maxLine={4}
                onReflow={(reFlow) =>
                  handleReflow(reFlow, true, job, `second-${job?.id}`)
                }
                basedOn="letters"
                className={`box-content box-content-second-${job?.id}`}
              />
            ) : (
              <div className="empty-box-content">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("No data")}
                />
              </div>
            )}
          </Box>
          {!editJob && (
            <Box>
              <ButtonWrapper>
                <SectionContent>
                  <Button
                    className="refer-btn loading-icon"
                    onClick={handleSelfSearch}
                  >
                    {t("同企業別職種一覧確認")}
                  </Button>
                  <List
                    size="small"
                    bordered
                    dataSource={job?.other_occupations_same_company || []}
                    renderItem={(item: any) => {
                      return (
                        <List.Item>
                          <a
                            href={
                              applicantId
                                ? `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs/detail/${item?.id}?applicantId=${applicantId}`
                                : `${process.env.NEXT_PUBLIC_AGENT_HOST}/jobs/detail/${item?.id}`
                            }
                            target="_blank"
                          >
                            {`${String(
                              item?.occupation_type_new?.map((i) => t(i))
                            )}${
                              item?.one_phrase ? `(${item?.one_phrase})` : ""
                            }`}
                          </a>
                        </List.Item>
                      )
                    }}
                  />
                </SectionContent>
                <SectionContent>
                  <Button
                    className="refer-btn loading-icon"
                    onClick={handleOtherSearch}
                  >
                    {t("同業企業の確認")}
                  </Button>

                  <List
                    size="small"
                    bordered
                    dataSource={
                      job?.other_companies_and_agent_companies_same_industry &&
                      job?.other_companies_and_agent_companies_same_industry
                        ?.length
                        ? sortObjectByKey(arrayToObject(filterdata)) // Sort the array in ascending order
                        : []
                    }
                    renderItem={(item: any) => (
                      <List.Item>
                        <a
                          onClick={() =>
                            handleSearchonClickCompanyName(
                              job?.industry_type_new,
                              item
                            )
                          }
                        >
                          {t(item.name)}
                        </a>
                      </List.Item>
                    )}
                  />
                </SectionContent>
              </ButtonWrapper>
            </Box>
          )}
          <BottomSection>
            <div></div>
            <div className="btn-wrapper download-btn">
              <a target="_blank">
                <Button
                  background="#fff"
                  color={theme.blue2}
                  icon={
                    pdfDownloading ? (
                      <Spin
                        indicator={antIcon}
                        style={{ marginRight: "8px" }}
                      />
                    ) : (
                      <DownloadOutlined />
                    )
                  }
                  className={"download-btn"}
                  disabled={pdfDownloading}
                  onClick={downloadPdf}
                >
                  {t("Download PDF")}
                </Button>
              </a>
              {isAgent && (
                <Button
                  className="refer-btn loading-icon"
                  onClick={
                    applicantId
                      ? () => pickUpApplicant(applicantId) //perform pickup functiononality
                      : showModal
                  }
                  loading={
                    applicantId
                      ? isLoadingApplicant ||
                        isFetchingApplicant ||
                        isLoadingReferJobSeeker
                      : false
                  }
                >
                  {applicantId ? t("Pick up") : t("Refer job seekers")}
                </Button>
              )}

              {!isAgent ? null : favourite ? (
                <Tooltip
                  placement="topLeft"
                  title={<span style={{ color: "black" }}>お気に入り削除</span>}
                  color="white"
                >
                  <HeartFilled
                    className="refer-btn"
                    onClick={() => handleClickFavorite(job?.id)}
                    disabled={isLoading || isFetching}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="topLeft"
                  title={
                    <span style={{ color: "black" }}>お気に入りに追加</span>
                  }
                  color="white"
                >
                  <HeartOutlined
                    className="refer-btn"
                    onClick={() => handleClickFavorite(job?.id)}
                    disabled={isLoading || isFetching}
                  />
                </Tooltip>
              )}
            </div>
          </BottomSection>
        </Col>
      </Row>
    </Card>
  )
  return (
    <Wrapper id={id} ref={ref}>
      <JobDetailModal
        isVisible={detailModalVisible}
        onClose={handleDetailModalClose}
        body={modalData?.body}
        title={modalData?.title}
      />
      {showRibbon ? (
        <Badge.Ribbon color="red" text={getBadgeValue(job)}>
          {card}
        </Badge.Ribbon>
      ) : (
        card
      )}
      <Modal
        title={t("Refer job seekers")}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={"calc(100% - 300px)"}
        style={{ left: "8rem", top: "5.5rem" }}
      >
        <div
          style={{
            height: "calc(100vh - 215px)",
            overflow: "hidden",
          }}
        >
          <JobSeekers
            jobID={job?.id}
            isAgentAdmin={isAgentAdmin}
            loginId={loginId}
          />
        </div>
      </Modal>
    </Wrapper>
  )
}

export { JobCard }
