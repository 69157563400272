import { Dispatch, createContext } from "react"
import { firebase } from "@project/shared"

type ContextProps = {
  loading: boolean
  user: firebase.User | null
  authenticated: boolean
  setUser: any
  role: any
  isAdmin: boolean
  currentUser: any
  setCurrentUser: Dispatch<any>
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  role: any | null
  isAdmin?: boolean
  setUser: any
  children: React.ReactNode
  currentUser: any
  setCurrentUser: Dispatch<any>
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    role,
    isAdmin,
    setUser,
    children,
    currentUser,
    setCurrentUser,
  } = props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        role,
        authenticated: user !== null,
        setUser,
        isAdmin,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
