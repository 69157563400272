import { useState, useEffect } from "react"
import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import Head from "next/head"
import { message } from "antd"
import { API, ChatProvider, analytics, firebase } from "@project/shared"
import { QueryClient, QueryClientProvider } from "react-query"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { AuthProvider } from "../utils/AuthContext"
import "../utils/css-imports"
import "../styles.css"
import { CloseCircleFilled } from "@ant-design/icons"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `owner-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null as firebase.User | null)
  const [currentUser, setCurrentUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const { t } = useTranslation()
  const [role, setRole] = useState(null)

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url)
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Skeleton-Owner",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        window.scrollTo(0, 0)
        logEvent(url)
      })

      logEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logEvent)
      }
    }
  }, [])

  useEffect(() => {
    const clearLocalJobFormData = () => {
      if (
        localStorage.getItem("jobFormState") === "1" &&
        (routers.asPath !== "/jobs/add" ||
          routers.asPath.indexOf("/jobs/edit") === -1)
      ) {
        localStorage.removeItem("jobFormState")
        localStorage.removeItem("jobForm")
      }
    }
    routers.events.on("routeChangeComplete", clearLocalJobFormData)
    return () => {
      routers.events.off("routeChangeComplete", clearLocalJobFormData)
    }
  }, [routers.events])

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user !== null) {
          const idToken = await user!.getIdTokenResult()
          if (
            idToken.claims["role"] === "super-admin" ||
            idToken.claims["role"] === "admin-member" ||
            idToken.claims["role"] === "admin-contact-person"
          ) {
            setUser(user)
            const loginUser = await API.get(`/admin-members/${user?.uid}`, {
              params: { is_fb_uid: true },
            })
            const hasUnseenMessages = await API.get(
              `/admin-chat/check-for-unseen-messages`
            )
            setCurrentUser({
              ...loginUser,
              hasUnseenMessages: hasUnseenMessages?.data,
            })
            setRole(idToken.claims["role"])
            setIsAdmin(true)
          } else {
            firebase.auth().signOut()
            setUser(null)
            setRole(null)
            message.error({
              key: "1",
              icon: <CloseCircleFilled onClick={() => message.destroy("1")} />,
              content: t("Unauthorized user"),
            })
          }
        }
        setLoading(false)
      } catch (error) {
        Sentry.captureException(error)
        message.error({
          key: "5",
          content: t("An error has occurred. Please try again later."),
          icon: <CloseCircleFilled onClick={() => message.destroy("5")} />,
        })
      }
    })
  }

  useEffect(() => {
    initialLoad()
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Sales Agent</title>
          <link rel="icon" href="/assets/favicon.ico" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
        </Head>
        <AuthProvider
          loading={loading}
          user={user}
          role={role}
          isAdmin={isAdmin}
          setUser={setUser}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        >
          <ChatProvider>
            <Component {...pageProps} />
          </ChatProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  )
}
export default MyApp
